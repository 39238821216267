.container_signin {
    min-height: 100vh;
    background-color: #F7F9FA;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    
}

.container_signup {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    min-height: 100vh;
    background-color: #323641;
}
.login{
   
}

.signup{
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-login{
    width: 70vw;
    background-color: white;
    border: solid thin rgb(236, 236, 236);
}

.login-header-1{
    color: white;
    font-weight: 100 !important;
    font-style: italic;
    text-align: center;
}

.card-signup{
    background-color: white;
    width: 80vw;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.logo-login{
    width: 200px;
}

.login-top{
    height: 10px;
    width: 350px;
    background-color: #2DE6DA
}

.signup-top{
    height: 8px;
    width: 100%;
    background-color: #2DE6DA
}

.login-contents{
    padding: 30px;
    
}

.forgot-text{
    text-align: center;
    font-size: 0.9em;
}


/* ************************* */

.welcome-text-login{
    font-size: 3em;
    font-weight: 300;
}