.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.bglight{
  background-color: #f2f2f2;
  padding: 20px;
}

.red-alert{
  color: red;
}


.table-form>tbody>tr{
  height: 80px;
}

.table-form>tbody>tr:nth-child(odd){
  background-color: #f9f9f9
}

.table-form>tbody>tr>td{
  padding: 10px;
}

*{
  font-family: 'Roboto', sans-serif;
}


a{
  color: inherit ;
  text-decoration: none;
}


/* Form styling like bootstrap */

.form-control{
  padding: 10px;
  border: solid thin lightgrey;
  border-radius: 2px;
  width: 100%;
}

.form-control[type=date]{
  padding: 8px;
  border: solid thin lightgrey;
  border-radius: 2px;
  width: 100%;
}

.form-control::placeholder{
  color: grey;
  opacity: 1;
}

/* Table styling */


.table{
  width: 100%;
  border-collapse: collapse;
  
}

.table thead {
  background-color: #9F52B0;
}
.table thead tr th{
  /* padding: 2px; */
  font-size: 1em;
  color: white;
  border: solid thin lightgrey;
}

.table thead tr td{
  padding: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  border: solid thin lightgrey;
}

.table tbody {
  background-color: white;
}

.table tbody tr td{
  padding: 5px;
  font-size: 0.8em;
  border: solid thin lightgrey;
}


.table tfoot {
  
}

.table tfoot tr td{
  padding: 5px;
  font-size: 0.9em;
  font-weight: 500;
  border: solid thin lightgrey;
}


.error-msg{
  padding: 5px;
  font-weight: 700;
  color: red;
  font-size: 11px;
}

.label-style-me{
  font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: block;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    overflow-wrap: break-word;
    
}

.required-start::after{
  content: " *";
    color: rgb(164, 38, 44);
    padding-right: 12px;
}


.my-table-own{
  width: 100%;
  border-collapse: collapse;
}

.my-table-own > thead > tr > th {
  border: 1px solid black;
  color: black;
  padding: 3px;
}

.my-table-own > tbody > tr > td {
  border: 1px solid black;
  color: black;
  padding: 3px;
}




.download-table-xls-button{
  background-color: #1A75FA;
  color: white;
  border: none;
  font-weight: 700;
  box-shadow: none;
  padding: 4px 4px 4px 4px;
  
}