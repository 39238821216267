.welcome{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.welcome-image{
    width: 30%
}

.welcome-text{
    text-align: center;
}

.welcome-text-color{
    color: #891AFF;
    font-weight: bold;
}